.App {
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #333;
  margin: 20px;
}

.App-header {
  text-align: left;
  max-width: 800px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
}

.App-header .spotify-playlist-header {
  text-align: center;
  font-weight: bold;
  font-size: 1.75em;
}

.header-image {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 0px;
  object-fit: cover;
}

.artwork-credit {
  font-size: 0.75em; /* Small font size for the artwork credit */
  margin-top: 0; /* Remove top margin */
  margin-bottom: 40px; /* Add some space below the credit text, adjust as needed */
  color: #555; /* Optional: a softer color for the artwork credit text */
  text-align: center; /* Center the text below the image */
}

.introduction-section .intro-title {
  font-size: 40px;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 40px; /* Add some space below the credit text, adjust as needed */
  text-align: center;
  color: #000;
}

.introduction-section .intro-text {
  font-size: 16px;
  text-align: left;
  max-width: 1200px;
  line-height: 1.6;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.sections-container {
  display: flex;
  justify-content: space-around; /* Adjusted for better spacing */
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 40px;
}

.socials-section, .projects-section {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  margin-bottom: 20px; /* Add space below each section */
}

.socials-section h2, .projects-section h2 {
  margin-bottom: 10px;
}

.social-button, .discover-daily, .project-item {
  margin: 5px;
  padding: 10px 20px;
  border: 1px solid #ddd;
  background-color: #eeeeee;
  color: black; /* Keep the text color black on hover */
  text-decoration: none; /* Remove the underline on hover */
  border-radius: 5px;
  cursor: pointer;
  text-align: center; /* Ensure text is centered within buttons */
}

.social-button:hover, .discover-daily:hover, .project-item:hover {
  background-color: #e8e8e8;
}

.projects-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Remove default margin */
  display: flex; /* Align list items horizontally */
  flex-direction: column; /* Stack list items vertically */
  align-items: center; /* Center list items */
}

.projects-section li {
  padding: 10px 20px;
  border: 1px solid #ddd;
  background-color: #eeeeee;
  color: black; /* Keep the text color black on hover */
  text-decoration: none; /* Remove the underline on hover */
  border-radius: 5px;
  margin: 5px; /* Add margin around list items */
}

/* Style for sliders and input fields */
.slider-container {
  margin: 10px 0;
  padding: 0 15px; /* Add padding to accommodate the slider thumbs */
}

.label-input-group {
  margin: 10px 0;
}

/* General label styles */
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* General input and slider styles */
input[type="text"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

input[type="range"] {
  -webkit-appearance: none; /* Important to remove default appearance */
  appearance: none;
  width: calc(100% - 30px); /* Adjust width as needed */
  margin: 10px 15px; /* Center the slider within its container */
  background: transparent; /* Remove default background */
  padding: 0; /* Reset padding */
  cursor: pointer;
}

/* Styles for the thumb for WebKit browsers */
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #4CAF50; /* Green color */
  border-radius: 50%; /* Circular shape */
  height: 20px;
  width: 20px;
  margin-top: -5px; /* Adjust this value if the track height changes */
  cursor: pointer;
}

/* Styles for the track for WebKit browsers */
.range-slider::-webkit-slider-runnable-track {
  background: #D9EDD9; /* Light green background for the track */
  height: 10px; /* Smaller height for the track */
  border-radius: 5px; /* Slightly rounded corners for the track */
}

/* Style for dropdown lists */
.dropdown-list {
  border: 1px solid #ddd;
  background-color: #fff;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  position: absolute; /* Position the dropdown absolutely */
  width: 100%; /* Match the width of the input field */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for dropdown */
  z-index: 10; /* Ensure the dropdown is above other elements */
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

/* Style for selected items list */
.selected-list {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
}

.selected-list ul {
  list-style-type: none;
  padding-left: 0;
}

.selected-list li {
  padding: 5px;
  margin: 5px 0;
  background-color: #eee;
  border-radius: 4px;
}

.selected-list li button {
  margin-left: 10px;
}

/* Button styles */
button {
  background-color: #4CAF50; /* A green background */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Error message styles */
.error-message {
  color: #D8000C;
  background-color: #FFBABA;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
}

/* Selected Items Container */
.dropdown-container {
  display: flex;
  justify-content: space-between; /* Space out the artist and genre lists */
  margin-bottom: 20px; /* Add some space below the container */
}

/* Selected Artists Styles */
.selected-artists {
  width: 48%; /* Set width to less than half to account for padding/margin */
  margin-right: 2%; /* Add margin for spacing between columns */
}

.selected-artists ul {
  list-style-type: none; /* Remove default list styling */
  padding-left: 0; /* Remove default padding */
}

.selected-artists li {
  background-color: #D9EDD9; /* Light green background */
  padding: 5px 10px;
  border-radius: 4px; /* Round corners */
  margin-bottom: 5px; /* Space between items */
  display: flex; /* Use flexbox for internal alignment */
  justify-content: space-between; /* Space out the content and button */
  align-items: center; /* Align items vertically */
}

/* Selected Genres Styles */
.selected-genres {
  width: 48%; /* Set width to less than half to account for padding/margin */
}

.selected-genres ul {
  list-style-type: none;
  padding-left: 0;
}

.selected-genres li {
  background-color: #D9EDD9;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Button Styles within Lists */
.selected-artists button,
.selected-genres button {
  padding: 2px 5px; /* Smaller padding for a button */
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 2px; /* Slightly rounded corners for the button */
  cursor: pointer; /* Pointer cursor on hover */
}

.selected-artists button:hover,
.selected-genres button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.make-playlist {
  display: flex; /* Use flexbox to center children */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 10vh; /* Take up full viewport height */
  flex-direction: column; /* Stack children vertically */
}

.make-playlist button {
  padding: 10px 20px; /* Add padding inside the button */
  margin: 10px 0; /* Add margin outside the button */
  /* Add other button styling as needed */
}

.make-playlist p {
  margin-top: 20px; /* Add space between button and error message if present */
  color: #D8000C; /* Example error color */
  /* Add other paragraph styling as needed */
}